import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { use100vh } from "react-div-100vh";
import { Footer } from "src/pages/Main/components/Footer";
import { Header } from "src/pages/Main/components/Header";
import { useMain } from "src/pages/Main/hooks/useMain";

function Main() {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const { transLogin, pcId, pcName, viewChat } = useMain(frameRef);
  const height = use100vh();

  const [videoBoxHeight, SetVideoBoxHeight] = useState<number>(0);
  const [wrapperMinHeight, SetWrapperMinHeight] = useState<number>(0);

  const [isFullScreenVideo, setIsFullScreenVideo] = useState(false);

  useEffect(() => {
    SetVideoBoxHeight(Number(height) - 48 - 96 - 16 - 28);
    SetWrapperMinHeight(Number(height));
  }, [height]);

  const videoWrapper = useRef<HTMLDivElement>(null);

  const [frameWidth, setFrameWidth] = useState<number>(0);
  const [frameHeight, setFrameHeight] = useState<number>(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((el) => {
        const wrapperWidth = el.contentRect.width;
        const wrapperHeight = el.contentRect.height;
        console.log(wrapperHeight / wrapperWidth);
        if (wrapperHeight / wrapperWidth > 0.5625) {
          setFrameWidth(wrapperWidth);
          setFrameHeight(wrapperWidth * 0.5625);
        } else {
          setFrameWidth(wrapperHeight / 0.5625);
          setFrameHeight(wrapperHeight);
        }
      });
    });
    if (videoWrapper.current) {
      observer.observe(videoWrapper.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  console.log(frameHeight);

  return (
    <Box zIndex="1">
      <Box
        style={{
          position: "relative",
          minHeight: wrapperMinHeight,
          height: isFullScreenVideo ? "100dvh" : "auto",
          width: isFullScreenVideo ? "100dvw" : "auto",
        }}
      >
        <Header isFullScreenVideo={isFullScreenVideo} isChat={viewChat} />
        <Box
          style={{
            position: "absolute",
            height: isFullScreenVideo
              ? "100%"
              : "calc(100% - 48px - 96px - 16px)",
            width: "100%",
            left: "0",
            top: isFullScreenVideo ? 0 : "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              background={isFullScreenVideo ? "#203E4D" : "grey"}
              style={{
                width: "100%",
                height: videoBoxHeight,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              ref={videoWrapper}
            >
              {/* <Box
              style={{
                width: "100%",
                height: '100%',
                maxWidth: '1920px',
                maxHeight: isFullScreenVideo ? "100%" : videoBoxHeight,
                aspectRatio: '16 / 9',
                margin: '0 auto',
              }}
              > */}
              <iframe
                allow="camera *;microphone *; fullscreen"
                ref={frameRef}
                title="rura-frame"
                src={""}
                style={{
                  backgroundColor: "#000",
                  border: "none",
                  padding: "0",
                }}
                width={isFullScreenVideo ? "100%" : frameWidth}
                height={isFullScreenVideo ? "100%" : frameHeight}
              />
              {/* </Box> */}
            </Box>
            {!isFullScreenVideo && (
              <Box width="100%" textAlign="center">
                <p style={{ fontSize: "16px", margin: "4px 0 0 0" }}>
                  {pcName && pcName !== "not_exist"
                    ? `店舗名：${pcName}`
                    : `店舗ID：${pcId}`}
                </p>
              </Box>
            )}
          </Box>
        </Box>
        <Footer
          transLogin={transLogin}
          setIsFullScreenVideo={setIsFullScreenVideo}
          isFullScreenVideo={isFullScreenVideo}
        />
      </Box>
    </Box>
  );
}

export default Main;
