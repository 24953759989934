import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { FiHelpCircle } from "react-icons/fi";
import logo from "src/images/rura_logo_blue.svg";

export const Header = () => (
  <Box
    style={{
      position: "fixed",
      width: "100%",
      height: "48px",
      left: "0",
      top: "0",
      backgroundColor: "#fff",
      zIndex: "3",
    }}
  >
    <Flex
      alignItems={"center"}
      style={{
        position: "absolute",
        left: "16px",
        top: "50%",
        transform: "translate(0,-50%)",
        width: "calc(100% - 32px)",
      }}
    >
      <Image src={logo} width="auto" height="20px" />
      <Text fontSize="12px" ml="4px" display={"block"}>
        スタッフ間接続
      </Text>
    </Flex>
    <HStack position="absolute" top="50%" right="16px">
      <Box
        p="3px"
        width="20px"
        height="20px"
        borderRadius="4px"
        cursor="pointer"
        style={{
          transform: "translate(0,-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        _hover={{
          background: "rgba(0, 0, 0, 0.13333)",
        }}
        onClick={() => {
          window.open(
            "https://support.timeleap-rura.com/",
            "_blank",
            "noreferrer"
          );
        }}
      >
        <FiHelpCircle
          style={{
            width: "16px",
            height: "16px",
          }}
        />
      </Box>
    </HStack>
  </Box>
);
