import { useCallback } from "react";
import { useEvent, useLatest } from "react-use";
import { ReceiveActionMessageEvent, ReceiveActionType } from "./event/receive";
import { isValidMessage } from "./isValidMessage";

/** 受信ハンドラ */
type ReceveHandler<A extends ReceiveActionType> = (
  event: Extract<ReceiveActionMessageEvent, { action: A }>
) => void;

/**
 * POST メッセージ受信専用 Hooks
 */
export const usePostReceveMessage = <A extends ReceiveActionType>(
  action: A,
  onReceve: ReceveHandler<A>
) => {
  const handleReceve = useLatest(onReceve);

  const handleListener = useCallback(
    (event: unknown) => {
      if (!isValidMessage(event)) return;

      const payload = JSON.parse(event.data);
      if (payload.action !== action) return;

      console.warn("RURA CONNECT でメッセージを受信しました", payload);
      handleReceve.current(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
    [handleReceve]
  );

  useEvent("message", handleListener, window);
};
