import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import Div100vh from "react-div-100vh";
import { Header } from "src/pages/Login/components/Header";
import { InputArea } from "src/pages/Login/components/InputArea";
import { useLogin } from "src/pages/Login/hooks/useLogin";

function Login() {
  const [isActive, setActive] = useState<boolean>(false);
  const {
    loginMethod,
    setLoginMethod,
    companyId,
    setCompanyId,
    login,
    userId,
    setUserId,
    branchAccountId,
    setBranchAccountId,
    password,
    setPassword,
    pcId,
    setPcId,
    alertText,
  } = useLogin(setActive);

  return (
    <Div100vh style={{ width: "100%" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100%",
        }}
      >
        <Box
          style={{
            padding: "48px 16px",
            width: "100%",
          }}
        >
          <Header
            text="情報を入力して接続開始を押してください。"
            isDetail={true}
          />
          <Box
            style={{ margin: "24px auto 0", width: "100%", maxWidth: "480px" }}
          >
            <Box
              style={{
                width: "100%",
                padding: "8px 24px 24px",
                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.16)",
                borderRadius: "4px",
              }}
            >
              <Box>
                {loginMethod === "user" ? (
                  <InputArea
                    text="スタッフID"
                    defaultValue={userId}
                    handleChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    placeholderText="スタッフIDを入力"
                  />
                ) : (
                  <>
                    {!process.env.REACT_APP_COMPANY && (
                      <InputArea
                        text="カンパニーID"
                        defaultValue={companyId}
                        handleChange={(e) => {
                          setCompanyId(e.target.value);
                        }}
                        placeholderText="カンパニーIDを入力"
                      />
                    )}
                    <InputArea
                      text="支店アカウントID"
                      defaultValue={branchAccountId}
                      handleChange={(e) => {
                        setBranchAccountId(e.target.value);
                      }}
                      placeholderText="支店アカウントIDを入力"
                    />
                  </>
                )}

                <InputArea
                  text={`パスワード${
                    loginMethod === "branchAccount" ? "(任意)" : ""
                  }`}
                  defaultValue={password}
                  handleChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholderText="パスワードを入力"
                />
                {loginMethod === "user" && (
                  <InputArea
                    text="店舗ID"
                    defaultValue={pcId}
                    handleChange={(e) => {
                      setPcId(e.target.value);
                    }}
                    placeholderText="店舗IDを入力"
                  />
                )}
                {/* <Box>
            <Text
              textAlign="right"
              textDecoration="underline"
              cursor="pointer"
              onClick={() => setNotificationView(!isNotificationView)}
            >
              {isNotificationView ? "通知の非表示" : "通知を設定する"}
            </Text>

            {isNotificationView && (
              <InputArea
                text="通知ID"
                defaultValue={notificationId}
                handleChange={(e) => {
                  setNotificationId(e.target.value);
                }}
                placeholderText="通知IDを入力"
              />
            )}
          </Box> */}
                <Box
                  width="100%"
                  height="52px"
                  mt="32px"
                  background={isActive ? "#00A0E9" : "rgba(0, 0, 0, 0.12)"}
                  borderRadius="4px"
                  cursor={isActive ? "pointer" : "not-allowed"}
                  _hover={{
                    bg: isActive
                      ? "rgba(0, 160, 233, 0.50)"
                      : "rgba(0, 0, 0, 0.12)",
                  }}
                  onClick={() => {
                    if (isActive) {
                      login("/select-notice");
                    }
                  }}
                >
                  <Text
                    pt="10px"
                    fontSize="18px"
                    fontWeight="500"
                    color={isActive ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)"}
                    textAlign="center"
                  >
                    ログイン
                  </Text>
                </Box>
                {alertText && (
                  <Text mt="10px" color="#EB1438" fontSize="14px">
                    {alertText}
                  </Text>
                )}
              </Box>
            </Box>
            <Box width="100%" m="16px auto">
              <Text
                textAlign="center"
                cursor="pointer"
                color="#00A0E9"
                onClick={() => {
                  setLoginMethod(
                    loginMethod === "branchAccount" ? "user" : "branchAccount"
                  );
                  localStorage.setItem(
                    "login_method",
                    loginMethod === "branchAccount" ? "user" : "branchAccount"
                  );
                }}
              >
                {loginMethod === "user"
                  ? "支店アカウントでログインする"
                  : "スタッフアカウントでログインする"}
              </Text>
            </Box>
            <Box style={{ margin: "32px auto 0", textAlign: "left" }}>
              <Text fontSize="12px" m="0">
                {loginMethod === "user"
                  ? "※スタッフID、パスワード、店舗IDが不明な場合は、貴社管理者にお問い合わせください。"
                  : "※支店アカウントID、パスワードが不明な場合は、貴社管理者にお問い合わせください。"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Div100vh>
  );
}

export default Login;
