import { CombinedError } from "urql";

const hasStatusBy = (status: number) => {
  return (err?: CombinedError) => {
    if (!err) return false;
    return err.graphQLErrors.some(({ extensions }) => extensions?.status === status);
  }
}

/** NotFount エラーであるか */
export const isNotFountError = hasStatusBy(404);
