import { ReceiveActionEvent } from "./const/message/event/receive";

export const ApproveFrameReceiveAction = [
  "open_rura_frame",
  ReceiveActionEvent.MIC_MUTE_RESULT,
  ReceiveActionEvent.VIDEO_MUTE_RESULT,
  ReceiveActionEvent.RECEPTION_START,
  ReceiveActionEvent.RECEPTION_END,
  ReceiveActionEvent.RURA_INIT,
  "during_wait",
  "during_rest",
  ReceiveActionEvent.MEDIA_DEVICE_RESULT,
  "request_full_screen",
  ReceiveActionEvent.VIDEO_UNAVAILABLE,
];
