import React from "react";

import { Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import SelectNotice from "./pages/SelectNotice";
import Login from "./pages/Login/index";
import LoginKoshidaka from "./pages/Login/koshidaka";
import ErrorPage from "./pages/Error/index";

function App() {
  const loginEnv = process.env.REACT_APP_LOGIN_ENV;
  return (
    <Switch>
      <Route exact path="/">
        <Main />
      </Route>
      <Route exact path="/select-notice">
        <SelectNotice />
      </Route>
      {loginEnv === "koshidaka" ? (
        <Route exact path="/login">
          <LoginKoshidaka />
        </Route>
      ) : (
        <Route exact path="/login">
          <Login />
        </Route>
      )}
      <Route exact path="/error">
        <ErrorPage />
      </Route>
    </Switch>
  );
}

export default App;
