import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { usePostReceveMessage } from "src/hooks/message/usePostReceveMessage";
import { ErrorPageState } from "src/pages/Error";

/**
 * マイク使用不可 Hooks
 */
export const useMicUnavailable = () => {
  const history = useHistory();

  const handleMicUnavailable = useCallback(() => {
    history.push("/error", {
      title: "マイク接続エラー",
      message: "マイク接続に問題があるので接続できませんでした。",
    } as ErrorPageState);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [history.push]);

  /** イベント受信時 */
  usePostReceveMessage("mic_unavailable", handleMicUnavailable);
};
