/**
 * 有効なメッセージであるか
 *
 * true が返る場合は JSON.parse できることを保証します。
 *
 * @returns true: 有効なメッセージである / false: 有効なメッセージでない
 */
export const isValidMessage = (event: unknown): event is MessageEvent => {
  if (event === null) return false;
  if (event === undefined) return false;
  if (typeof event !== "object") return false;
  if (typeof (event as MessageEvent).data !== "string") return false;

  return true;
};
