export const FRAME_RECEIVE_ACTION = {
  CHAT_RESIZE: "chat_resize",
  UNREAD_MESSAGES_NUM: "unread_messages_num",
  CHAT_CLOSE: "chat_close",
} as const;

export const FRAME_SEND_ACTION = {
  CHANGE_STAUTS: "change_status",
  CHAT_OPEN: "chat_open",
  CHAT_CLOSE: "chat_close",
} as const;

export const ApproveFrameReceiveAction = [
  FRAME_RECEIVE_ACTION.CHAT_RESIZE,
  FRAME_RECEIVE_ACTION.UNREAD_MESSAGES_NUM,
  FRAME_RECEIVE_ACTION.CHAT_CLOSE,
];

export const HEADER_HEIGHT = 45;
