import { addZero } from "./TextUtil";
export default class DateTime {
  toString(datetime: Date) {
    const year = datetime.getFullYear();
    const month = datetime.getMonth() + 1;
    const day = datetime.getDate();
    const hour = datetime.getHours();
    const minute = datetime.getMinutes();
    const second = datetime.getSeconds();
    return `${year}/${month}/${day}-${hour}:${minute}:${second}`;
  }

  toTimeString(datetime: Date) {
    const hour = datetime.getHours();
    const minute = datetime.getMinutes();
    const second = datetime.getSeconds();
    return `${addZero(hour)}:${addZero(minute)}:${addZero(second)}`;
  }

  now() {
    const dateTime = new DateTime();
    return dateTime.toString(new Date());
  }
}
