import { Box, Icon, Text } from "@chakra-ui/react";
import { CSSProperties, forwardRef, useMemo } from "react";
import { IconType } from "react-icons";

type Props = {
  /** アイコン */
  icon: IconType;

  /**  */
  iconSize: string;

  /** 表示テキスト */
  text: string;

  /** ボタン色 */
  color: "red" | "blue";

  /** */
  style: CSSProperties;

  /** 接客中であるか */
  isReception?: boolean;

  /** 非活性にするか（デフォルト: false） */
  disabled?: boolean;

  /** クリック時ハンドラ */
  onClick?: () => void;
};

export const CircleButton = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    icon,
    iconSize,
    text,
    onClick,
    color,
    style,
    isReception = true,
    disabled = false,
  } = props;

  /** 背景色 */
  const background = useMemo(() => {
    if (disabled) return "rgba(0, 0, 0, 0.12)";
    if (color === "red") return "#EB1438";
    if (isReception) return "#00A0E9";

    return "rgba(0, 0, 0, 0.12)";
  }, [disabled, color, isReception]);

  /** ホバー時の背景色 */
  const bgInHover = useMemo(() => {
    if (disabled) return "rgba(0, 0, 0, 0.12)";
    if (color === "red") return "#BC102D";
    if (isReception) return "#0080BA";

    return "rgba(0, 0, 0, 0.12)";
  }, [disabled, color, isReception]);

  /** マウスポインター */
  const pointer = useMemo(() => {
    if (disabled) return "not-allowed";
    if (isReception) return "pointer";

    return "not-allowed";
  }, [disabled, isReception]);

  /** クリック時ハンドラ */
  const handleClick = () => {
    if (disabled) return;

    onClick?.();
  };

  return (
    <Box width="56px" style={style} ref={ref}>
      <Box
        width="56px"
        height="56px"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
        background={background}
        cursor={pointer}
        _hover={{ bg: bgInHover }}
        _focus={{
          bg: color === "red" ? "#8D0C22" : "#00608C",
        }}
        onClick={handleClick}
      >
        <Icon
          as={icon}
          color="#FFFFFF"
          sx={
            iconSize === "small"
              ? {
                  width: "30px",
                  height: "30px",
                }
              : {
                  width: "40px",
                  height: "40px",
                }
          }
        />
      </Box>
      <Text fontSize="11px" color="#37352F" m="4px 0px" textAlign="center">
        {text}
      </Text>
    </Box>
  );
});
