import { Box, Stack, Text } from "@chakra-ui/react";
import { Header } from "src/pages/Login/components/Header";
import { FC, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { gql } from "urql";
import { Feature, useMeQuery } from "src/gql/generated/graphql";
import { useFeatureToggle } from "src/hooks/useFeatureToggle";
import { LogoutButton } from "src/components/Button/LogoutButton";
import { SelectNoticeButtonList } from "src/components/SelectNoticeButtonList";
import { NotificationButtons } from "src/api/getNotifications";
export const MeQuery = gql`
  fragment FeatureToggle on FeatureToggle {
    feature
    enabled
  }
  fragment MeField on User {
    id
    datastoreId
    name
    role
    company {
      id
      datastoreId
      name
      recordFlg
      featureToggles {
        ...FeatureToggle
      }
    }
  }
  query me {
    me {
      ...MeField
    }
  }
`;

export const SelectNotice: FC = () => {
  const notifications = localStorage.getItem("notifications");
  const pcName = localStorage.getItem("pc_name");
  const pcId = localStorage.getItem("pc_id");
  const notificationButtons = (notifications
    ? JSON.parse(notifications)
    : []) as unknown as NotificationButtons[];
  const { isEnable } = useFeatureToggle();
  const [result] = useMeQuery();
  const [viewChat, setViewChat] = useState<boolean>(false);

  useEffect(() => {
    if (!pcName && !pcId) {
      window.location.href = "/login";
    }
  }, [pcId, pcName]);

  useEffect(() => {
    if (result.data?.me === undefined) return;
    // 支店アカウントログインの場合は、user情報が存在しない
    // チャット機能を非表示にする際に条件の追加
    const isChat =
      result.data?.me.datastoreId &&
      isEnable(result.data?.me.company.featureToggles, Feature.Chat);
    localStorage.setItem("view_chat", isChat ? "TRUE" : "FALSE");
    setViewChat(isChat ? true : false);
  }, [isEnable, result]);

  const allNotificationButtons = notificationButtons.filter(
    (notificationButton) => notificationButton.user_group_ids.length === 0
  );
  const groupNotificationButtons = notificationButtons.filter(
    (notificationButton) => notificationButton.user_group_ids.length > 0
  );

  const hasNotification =
    allNotificationButtons.length > 0 || notificationButtons.length > 0;

  return (
    <Div100vh style={{ position: "relative" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100%",
        }}
      >
        <Box
          style={{
            padding: "48px 16px",
            width: "100%",
          }}
        >
          <Header
            text="接続方法を選択してください。"
            isDetail={false}
            isChat={viewChat}
          />
          <Box
            style={{ margin: "24px auto 0", width: "100%", maxWidth: "480px" }}
          >
            <Stack
              gap={"32px"}
              style={{
                width: "100%",
                padding: "24px 24px 24px",
                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.16)",
                borderRadius: "4px",
              }}
            >
              {!hasNotification && (
                <Box
                  style={{
                    width: "100%",
                    height: "52px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  backgroundColor={"#00A0E9"}
                  _hover={{
                    bg: "#0080BA",
                  }}
                  onClick={() => {
                    localStorage.setItem("notification_id", "");
                    window.location.href = "/";
                  }}
                >
                  <Text fontSize="18px" fontWeight="500" color={"#FFFFFF"}>
                    通知なしで接続
                  </Text>
                </Box>
              )}
              <SelectNoticeButtonList
                title="全体通知"
                notificationButtons={allNotificationButtons}
              />
              <SelectNoticeButtonList
                title="グループ通知"
                notificationButtons={groupNotificationButtons}
              />
            </Stack>
            <LogoutButton onClick={() => (window.location.href = "/login")} />
          </Box>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default SelectNotice;
