import { Box, Text, Input } from "@chakra-ui/react";

export const InputArea = ({
  text,
  placeholderText,
  defaultValue,
  handleChange,
}: {
  text: string;
  placeholderText: string;
  defaultValue?: string | number | readonly string[] | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}) => {
  return (
    <Box style={{ padding: "16px 0 0", display: "block", textAlign: "left" }}>
      <Text fontSize="14px">{text}</Text>
      <Box style={{ margin: "8px 0 0" }}>
        <Input
          width="100%"
          height="50px"
          fontSize="16px"
          border="1px solid rgba(0, 0, 0, 0.13)"
          defaultValue={defaultValue}
          onChange={handleChange}
          pl="16px"
          placeholder={placeholderText}
          type={text.includes("パスワード") ? "password" : "text"}
          boxSizing="border-box"
        />
      </Box>
    </Box>
  );
};
