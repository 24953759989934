import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FiHelpCircle } from "react-icons/fi";
import { ChatFrame } from "src/components/ChatFrame";
import logo from "src/images/rura_logo_blue.svg";

export const Header = ({
  text,
  isDetail,
  isChat = false,
}: {
  text: string;
  isDetail: boolean;
  isChat?: boolean;
}) => {
  const viewChat = isChat && process.env.REACT_APP_CHAT_URL ? true : false;

  return (
    <Box style={{ textAlign: "center" }}>
      <Flex
        position="absolute"
        top="0"
        left="0"
        width="100%"
        alignItems="center"
        justifyContent="flex-end"
        padding="8px 16px"
      >
        {viewChat && <ChatFrame />}
        <Box
          width="20px"
          height="20px"
          _hover={{
            background: "rgba(0, 0, 0, 0.13333)",
          }}
          p="3px"
          cursor="pointer"
          borderRadius="4px"
          onClick={() => {
            window.open(
              "https://support.timeleap-rura.com/",
              "_blank",
              "noreferrer"
            );
          }}
        >
          <FiHelpCircle />
        </Box>
      </Flex>

      <Box width="100%">
        <Image
          src={logo as string}
          alt="タイムリープロゴ"
          w="320px"
          h="32px"
          m="0 auto 4px"
        />
      </Box>
      <Box>
        <Text fontSize="14px">スタッフ間接続</Text>
      </Box>
      <Box style={{ margin: "24px 0 0" }}>
        {isDetail && (
          <Text fontSize="18px">
            この端末からRURAに接続して、
            <br />
            遠隔スタッフとビデオ通話ができます。
          </Text>
        )}
        <Text
          fontSize="14px"
          style={{
            margin: "16px 0 0",
          }}
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
};
