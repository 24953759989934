import { Box, Text } from "@chakra-ui/layout";
import { FC } from "react";
import { NotificationButtons } from "src/api/getNotifications";

type SelectNoticeButtonListProps = {
  notificationButtons: NotificationButtons[];
  title: string;
};

export const SelectNoticeButtonList: FC<SelectNoticeButtonListProps> = (
  props
) => {
  const { notificationButtons, title } = props;

  if (notificationButtons.length === 0) return null;
  return (
    <Box
      style={{
        margin: "0px",
        textAlign: "center",
      }}
    >
      <Text fontSize="16px" color="#000000">
        {title}
      </Text>
      {notificationButtons.map((notificationButton, index) => (
        <Box
          key={index}
          style={{
            width: "100%",
            height: "52px",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid #00A0E9",
            margin: "16px 0 0 0",
          }}
          _hover={{
            bg: "#E5F5FD",
          }}
          onClick={() => {
            localStorage.setItem("notification_id", notificationButton.id);
            window.location.href = "/";
          }}
        >
          <Text fontSize="18px" fontWeight="500" color="#00A0E9">
            {notificationButton.view_data}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
