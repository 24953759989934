import { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useKoshidakaLogin } from "src/pages/Login/hooks/useKoshidakaLogin";
import { InputArea } from "src/pages/Login/components/InputArea";
import { Header } from "src/pages/Login/components/Header";

function Login() {
  const [isActive, setActive] = useState<boolean>(false);
  const { login, shopNumber, setShopNumber } = useKoshidakaLogin(setActive);
  return (
    <Box textAlign="center" height="95vh">
      <Header text="入力して接続開始を押してください。" isDetail={true} />
      <Box
        textAlign="left"
        width="368px"
        m="auto"
        boxShadow="0px 1px 8px rgba(0, 0, 0, 0.16)"
        borderRadius="4px"
      >
        <Box p="24px">
          <InputArea
            text="店舗番号"
            defaultValue={shopNumber}
            handleChange={(e) => {
              setShopNumber(Number(e.target.value));
              if (e.target.value.length > 3) {
                setActive(true);
              } else {
                setActive(false);
              }
            }}
            placeholderText="店舗番号を入力"
          />
          <Box
            width="320px"
            height="50px"
            mt="5px"
            background={isActive ? "#00A0E9" : "rgba(0, 0, 0, 0.12)"}
            borderRadius="4px"
            cursor={isActive ? "pointer" : "not-allowed"}
            _hover={{
              bg: isActive ? "rgba(0, 160, 233, 0.50)" : "rgba(0, 0, 0, 0.12)",
            }}
            onClick={() => {
              if (isActive) {
                login(shopNumber);
              }
            }}
          >
            <Text
              pt="10px"
              fontSize="18px"
              fontWeight="500"
              color={isActive ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)"}
              textAlign="center"
            >
              接続開始
            </Text>
          </Box>
        </Box>
      </Box>

      <Box m="64px auto" textAlign="left" width="380px">
        <Text fontSize="12px" m="0">
          ※店舗番号が不明な場合は、御社管理者にお問い合わせください。
        </Text>
        <Text fontSize="12px" m="0">
          ※1111: admin_pc,それ以外ではdev_pcにつながります。
        </Text>
      </Box>
    </Box>
  );
}

export default Login;
