import { Box, Text } from "@chakra-ui/react";

export type LogoutButtonProps = {
  onClick?: () => void;
};

export const LogoutButton = (props: LogoutButtonProps) => (
  <Box
    style={{
      margin: "24px 0 0",
      borderRadius: "4px",
      border: "1px solid #878682",
      cursor: "pointer",
      color: "#37352F",
      boxSizing: "border-box",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: "32px",
    }}
    _hover={{
      bg: "#EAEAEA",
    }}
    onClick={props.onClick}
  >
    <Text fontSize="14px" fontWeight="500" padding={"0 16px"}>
      ログアウト
    </Text>
  </Box>
);
