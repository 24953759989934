export const isDevEnv = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === "development";
};

export const isTestEnv = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === "test";
};

export const env = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      return "prod";
    case "staging":
      return "staging";
    case "demo":
      return "demo";
    default:
      return "development";
  }
};
