import { Center, Text, VStack } from "@chakra-ui/react";
import Div100vh from "react-div-100vh";
import { FiAlertCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { LogoutButton } from "src/components/Button/LogoutButton";
import { Header } from "./components/Header";

export type ErrorPageState = {
  /** 見出し */
  title: string;

  /** エラーメッセージ */
  message: string;
};

/**
 * エラーページ
 *
 * @example
 * const history = useHistory();
 * history.push(
 *    "/error",
 *    {
 *      title: "エラーが発生しました",
 *      message: "エラーメッセージ",
 *    } as ErrorPageState,
 * );
 */
export const ErrorPage = () => {
  const location = useLocation();
  const state = location.state as ErrorPageState;

  const title = state?.title ?? "エラー";
  const message = state?.message ?? "エラーが発生しました。";

  return (
    <Div100vh style={{ position: "relative" }}>
      <Header />
      <Center boxSize="100%" flexDirection="column" gap="16px">
        <VStack>
          <FiAlertCircle size="36px" color="#EB1438" />
          <Text fontSize="1.125rem">{title}</Text>
        </VStack>
        <Text
          textAlign="center"
          fontSize="0.875rem"
          w={{ base: "192px", sm: "100%" }}
        >
          {message}
        </Text>
        <LogoutButton onClick={() => (window.location.href = "/login")} />
      </Center>
    </Div100vh>
  );
};

export default ErrorPage;
