import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FiSettings, FiVideo } from "react-icons/fi";
import { SelectBox } from "src/components/SelectBox";
import { Devices, UseDevice } from "src/interface/Devices";

export const DeviceSettingModal = ({
  isOpen,
  onClose,
  devices,
  cameraDeviceId,
  setCameraDeviceId,
  usingDevice,
}: {
  isOpen: boolean;
  onClose: () => void;
  devices: Devices;
  micDeviceId: string | undefined;
  setMicDeviceId: (micDeviceId: string | undefined) => void;
  cameraDeviceId: string | undefined;
  setCameraDeviceId: (cameraDeviceId: string | undefined) => void;
  usingDevice: UseDevice | undefined;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            fontFamily="BlinkMacSystemFont"
            pl="0"
            ml="33px"
            fontSize="16px"
            fontWeight="400"
          >
            <Flex>
              <FiSettings
                style={{ marginTop: "4px", width: "20px", height: "20px" }}
              />
              <Text ml="10px" fontSize="20px">
                設定
              </Text>
            </Flex>
          </ModalHeader>
          <ModalBody
            ml="33px"
            mr="27px"
            p="0"
            borderTop="solid 1px rgba(0, 0, 0, 0.25)"
          >
            {/* <Box
              mt="20px"
              pb="15px"
              borderBottom="solid 1px rgba(0, 0, 0, 0.25)"
            >
              <Flex>
                <FiMic style={{ width: "20px", height: "20px" }} />
                <Text ml="15px">使用マイク</Text>
              </Flex>
              {usingDevice && usingDevice.audio && (
                <Text ml="0px">使用中:{usingDevice.audio.label}</Text>
              )}
              <SelectBox
                data={devices.micDevices}
                onChange={(deviceId: string) => {
                  setMicDeviceId(deviceId);
                }}
                defaultValue={micDeviceId}
              />
            </Box> */}
            <Box mt="20px">
              <Flex>
                <FiVideo style={{ width: "20px", height: "20px" }} />
                <Text ml="15px">使用カメラ</Text>
              </Flex>
              {usingDevice && usingDevice.video && (
                <Text ml="0px">使用中:{usingDevice.video.label}</Text>
              )}
              <SelectBox
                data={devices.videoDevices}
                onChange={(deviceId: string) => {
                  setCameraDeviceId(deviceId);
                }}
                defaultValue={cameraDeviceId}
              />
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
