import { useEffect, useState } from "react";

export const useParams = () => {
  const [once, setOnce] = useState(false);
  const [url, setUrl] = useState("");
  const [pcId, setPcId] = useState("");
  const [pcName, setPcName] = useState("");
  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [startNotice, setStartNotice] = useState<string>("");
  const [branchAccountId, setBranchAccountId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [loginMethod, setLoginMethod] = useState<string>("branchAccount");
  const osType = null;
  const memberId = null;
  const screenIds = null;

  useEffect(() => {
    const storageLoginMethod = localStorage.getItem("login_method");
    setLoginMethod(storageLoginMethod ? storageLoginMethod : "branchAccount");

    const storageCompanyId = localStorage.getItem("company_id");
    setCompanyId(storageCompanyId ? storageCompanyId : "");

    const storageBranchAccountId = localStorage.getItem("branch_account_id");
    setBranchAccountId(
      storageBranchAccountId ? storageBranchAccountId : "not_exist"
    );

    const storageUserId = localStorage.getItem("user_id");
    setUserId(storageUserId ? storageUserId : "");

    const storagePassword = localStorage.getItem("password");
    setPassword(storagePassword ? storagePassword : "");

    const storageNotificationId = localStorage.getItem("notification_id");
    setStartNotice(storageNotificationId ? storageNotificationId : "");

    const storagePcId = localStorage.getItem("pc_id");
    setPcId(
      storagePcId && storageUserId && storagePassword
        ? storagePcId
        : "not_exist"
    );
    const storagePcName = localStorage.getItem("pc_name");
    setPcName(storagePcName ? storagePcName : "not_exist");
  }, []);

  useEffect(() => {
    if (!once && (pcId || branchAccountId)) {
      if (loginMethod === "user") {
        if (pcId === "not_exist") {
          window.location.href = "/login";
        }
      } else {
        if (branchAccountId === "not_exist") {
          window.location.href = "/login";
        }
      }

      if (
        loginMethod ||
        (userId && password && pcId) ||
        (companyId && branchAccountId && password) ||
        osType ||
        startNotice ||
        memberId ||
        screenIds
      ) {
        let count = 0;
        let tmpUrl = `${process.env.REACT_APP_RURA_FRAME}/frame`;
        if (loginMethod === "user") {
          if (userId && userId !== "null") {
            tmpUrl += `${count === 0 ? "?" : "&"}user=${userId}`;
            count += 1;
          }
          if (password && password !== "null") {
            tmpUrl += `${count === 0 ? "?" : "&"}pass=${password}`;
            count += 1;
          }
          if (pcId && pcId !== "null") {
            tmpUrl += `${count === 0 ? "?" : "&"}pc_id=${pcId}`;
            count += 1;
          }
        } else {
          if (branchAccountId && branchAccountId !== "null") {
            tmpUrl += `${
              count === 0 ? "?" : "&"
            }branch_account=${branchAccountId}`;
            count += 1;
          }
          if (password && password !== "null") {
            tmpUrl += `${count === 0 ? "?" : "&"}pass=${password}`;
            count += 1;
          }
        }
        if (companyId && companyId !== "null") {
          tmpUrl += `${count === 0 ? "?" : "&"}company=${companyId}`;
          count += 1;
        }
        if (osType && osType !== "null") {
          tmpUrl += `${count === 0 ? "?" : "&"}os_type=${osType}`;
          count += 1;
        }
        if (startNotice && startNotice !== "null") {
          tmpUrl += `${count === 0 ? "?" : "&"}start_notice=${startNotice}`;
          count += 1;
        }
        if (memberId && memberId !== "null") {
          tmpUrl += `${count === 0 ? "?" : "&"}member_id=${memberId}`;
          count += 1;
        }
        if (screenIds && screenIds !== "null") {
          tmpUrl += `${count === 0 ? "?" : "&"}screen_ids=${screenIds}`;
          count += 1;
        }
        setUrl(tmpUrl);
      }
      setOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [loginMethod, pcId, branchAccountId]);

  return {
    url,
    userId,
    password,
    companyId,
    pcId,
    pcName,
    osType,
    startNotice,
    memberId,
    screenIds,
  };
};
