import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { getNotifications } from "src/api/getNotifications";
import { isNotFountError } from "src/gql/error";
import {
  useGetPcQuery,
  useSigninAsBranchAccountMutation,
  useSigninMutation,
} from "src/gql/generated/graphql";
import { orDefault } from "src/util/string";
import { gql } from "urql";
import { cookieKeyToken } from "src/util/token";

export const SigninQuery = gql`
  mutation Signin($userId: String!, $password: String!) {
    signin(input: { userId: $userId, password: $password }) {
      __typename
      ... on SigninSuccess {
        token
      }
      ... on SigninFailed {
        message
      }
      ... on SigninFrozen {
        message
      }
      ... on SigninRejection {
        message
      }
    }
  }
`;

export const SigninAsBranchAccountQuery = gql`
  mutation SigninAsBranchAccount(
    $branchUniqueId: String!
    $company: ID!
    $password: String
  ) {
    signinAsBranchAccount(
      input: {
        branchUniqueId: $branchUniqueId
        company: $company
        password: $password
      }
    ) {
      ... on SigninAsBranchAccountSuccess {
        token
        branchAccount {
          branch {
            id
            pcs {
              datastoreId
              name
            }
          }
        }
      }
      ... on SigninAsBranchAccountFailed {
        reason
      }
    }
  }
`;

export const pcQuery = gql`
  query getPc($datastoreId: String!) {
    pc(datastoreId: $datastoreId) {
      id
      datastoreId
      name
    }
  }
`;

export const useLogin = (setActive: (isActive: boolean) => void) => {
  const [loginMethod, setLoginMethod] = useState<"user" | "branchAccount">(
    "branchAccount"
  );
  const [userId, setUserId] = useState<string>("");
  const [branchAccountId, setBranchAccountId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [pcId, setPcId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [notificationId, setNotificationId] = useState<string>("");
  const [alertText, setAlertText] = useState<string>("");

  const [, signin] = useSigninMutation();

  const [, signinBrancAccount] = useSigninAsBranchAccountMutation();
  const [{ data: pcData, error }, fetchPc] = useGetPcQuery({
    variables: { datastoreId: pcId as string },
    pause: true,
  });

  useEffect(() => {
    if (!pcData) return undefined;
    localStorage.setItem("pc_name", pcData.pc.name);
  }, [pcData]);

  useEffect(() => {
    if (!error) return;

    if (isNotFountError(error)) {
      setAlertText("指定した店舗IDが存在しません。");
    }
  }, [error]);

  const loginDomain = process.env.REACT_APP_LOGIN_DOMAIN;

  const initial = () => {
    const cookie = new Cookies();
    cookie.remove("connect-token", {
      maxAge: 10 * 24 * 60 * 60,
      path: "/",
      domain: loginDomain,
    });
    localStorage.removeItem("notifications");
    localStorage.removeItem("start_notice");
    localStorage.removeItem("member_id");
    localStorage.removeItem("pass");
    localStorage.removeItem("use_camera");
    localStorage.removeItem("screen_ids");
    localStorage.removeItem("use_media");
    localStorage.removeItem("only_once");
    localStorage.removeItem("type");
    localStorage.removeItem("view_video");
    localStorage.removeItem("view_chat");
  };

  const login = async (path: string) => {
    if (loginMethod === "user") {
      // user_id, password, 店舗IDでのログイン
      await signin({ userId, password })
        .then(async (result) => {
          const { data } = result;
          switch (data?.signin.__typename) {
            case "SigninSuccess": {
              const cookie = new Cookies();
              cookie.set("connect-token", data.signin.token, {
                maxAge: 10 * 24 * 60 * 60,
                path: "/",
                domain: loginDomain,
              });
              cookie.set(cookieKeyToken, data.signin.token, {
                maxAge: 10 * 24 * 60 * 60,
                path: "/",
                domain: loginDomain,
              });
              localStorage.setItem("user_id", userId);
              localStorage.setItem("password", password);
              localStorage.setItem("pc_id", pcId);
              localStorage.setItem("notification_id", notificationId);
              return data.signin.token;
            }
            case "SigninFailed":
              throw "スタッフIDまたはパスワードが間違っています。";
            case "SigninFrozen":
              throw "ログイン試行回数制限を迎えました。管理者に連絡してください。";
            case "SigninRejection":
              throw "勤務開始が許可されたIPアドレスではありません";
            default:
              throw "ログインに失敗しました。";
          }
        })
        .then((token) => {
          fetchPc({ variables: { datastoreId: pcId } });
          return token;
        })
        .then(async (token) => {
          const result = await getNotifications(pcId, token);
          if (result[0] === 200) {
            localStorage.setItem(
              "notifications",
              JSON.stringify(result[1].data.notification_buttons)
            );
          }
        })
        .then(() => {
          setAlertText("");
          window.location.href = path;
        })
        .catch((err: unknown) => {
          if (typeof err === "string") setAlertText(err);
          console.warn(err);
        });
    } else {
      signinBrancAccount({
        branchUniqueId: branchAccountId,
        company: `Company:${companyId}`,
        password: orDefault(password, undefined),
      }).then(async (result) => {
        const { data } = result;
        switch (data?.signinAsBranchAccount.__typename) {
          case "SigninAsBranchAccountSuccess":
            {
              const cookie = new Cookies();
              const token = data.signinAsBranchAccount.token;
              cookie.set("connect-token", token, {
                maxAge: 10 * 24 * 60 * 60,
                sameSite: "none",
                secure: true,
              });
              cookie.set(cookieKeyToken, token, {
                maxAge: 10 * 24 * 60 * 60,
                sameSite: "none",
                secure: true,
              });
              localStorage.setItem("company_id", companyId);
              localStorage.setItem("branch_account_id", branchAccountId);
              // pc_idがユーザログインでログインしたものが残っている可能性があるため
              localStorage.removeItem("pc_id");
              localStorage.setItem("password", password);
              localStorage.setItem("notification_id", notificationId);

              const pcs = data.signinAsBranchAccount.branchAccount.branch.pcs;
              if (!pcs || pcs.length === 0) {
                console.error("支店に店舗が紐づいていません");
                setAlertText("支店に店舗が紐づいていません");
                return true;
              }
              localStorage.setItem("pc_id", pcs[0].datastoreId);
              localStorage.setItem("pc_name", pcs[0].name);

              const result = await getNotifications(pcs[0].datastoreId, token);
              if (result[0] === 200) {
                localStorage.setItem(
                  "notifications",
                  JSON.stringify(result[1].data.notification_buttons)
                );
              }

              setAlertText("");
              if (path) window.location.href = path;
            }
            break;
          case "SigninAsBranchAccountFailed":
            setAlertText("支店アカウントIDまたはパスワードが間違っています。");
            break;
          default:
            break;
        }
      });
    }
  };

  useEffect(() => {
    initial();
    let storageCompanyId = localStorage.getItem("company_id");
    if (process.env.REACT_APP_COMPANY) {
      storageCompanyId = process.env.REACT_APP_COMPANY;
    }
    if (storageCompanyId && !companyId) {
      setCompanyId(storageCompanyId);
    }

    const storageUserId = localStorage.getItem("user_id");
    if (storageUserId && !userId) {
      setUserId(storageUserId);
    }
    const storageBranchAccountId = localStorage.getItem("branch_account_id");
    if (storageBranchAccountId && !branchAccountId) {
      setBranchAccountId(storageBranchAccountId);
    }
    const storagePassword = localStorage.getItem("password");
    if (storagePassword && password == null) {
      setPassword(storagePassword);
    }
    const storagePcId = localStorage.getItem("pc_id");
    if (storagePcId && !pcId) {
      setPcId(storagePcId);
    }
    const storageNotificationId = localStorage.getItem("notification_id");
    if (storageNotificationId && !notificationId) {
      setNotificationId(storageNotificationId);
    }
    const storageLoginMethod = localStorage.getItem("login_method");
    if (storageLoginMethod) {
      setLoginMethod(storageLoginMethod as "user" | "branchAccount");
    }
    if (
      loginMethod === "user" &&
      storageUserId &&
      storagePassword &&
      storagePcId
    ) {
      setActive(true);
    }
    if (
      loginMethod === "branchAccount" &&
      storageBranchAccountId &&
      storageCompanyId
    ) {
      setActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, []);

  useEffect(() => {
    if (loginMethod === "user") {
      if (userId && password && pcId) setActive(true);
      else setActive(false);
    } else {
      if (branchAccountId && companyId) setActive(true);
      else setActive(false);
    }
  }, [
    loginMethod,
    companyId,
    userId,
    password,
    pcId,
    branchAccountId,
    setActive,
  ]);

  return {
    loginMethod,
    setLoginMethod,
    login,
    companyId,
    setCompanyId,
    userId,
    setUserId,
    branchAccountId,
    setBranchAccountId,
    password,
    setPassword,
    pcId,
    setPcId,
    notificationId,
    setNotificationId,
    alertText,
  };
};
