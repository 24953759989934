import { PcId } from "../interface/pc";

export interface NotificationButtons {
  id: string;
  key_text: string;
  value: string;
  button_type: number;
  view_data: string;
  web_hook_text: string;
  web_hook_url: string;
  user_group_ids: number[];
}

export const getNotifications = async (pcId: PcId, token: string) => {
  const url = `${process.env.REACT_APP_LOGIN_API_DOMAIN}/management/notification_button/pc/${pcId}`;
  const headers = new Headers();
  headers.append("Authentication-Token", token);
  headers.append("Content-Type", "application/json");
  headers.append("Call-Systen", "ShopStaff");
  try {
    const res = await fetch(url, {
      method: "GET",
      headers,
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
