import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Flex, Icon, Button, Text, Tooltip } from "@chakra-ui/react";
import { FC, memo, useEffect, useRef } from "react";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { useMessageEvent } from "src/components/ChatFrame/hooks/useMessageEvent";
import { HEADER_HEIGHT } from "./const";

export const ChatFrame: FC = memo(() => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    frameWidth,
    frameHeight,
    unreadAllChatMessages,
    isNewMessageText,
    openChat,
    closeChat,
  } = useMessageEvent({
    onMenuClose: onClose,
  });
  const url = process.env.REACT_APP_CHAT_URL;
  const viewURL = () => {
    if (frameRef && frameRef.current) {
      frameRef.current.src = `${url}/`;
    }
  };

  useEffect(() => {
    viewURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [frameRef, frameRef.current, url]);

  const onChatOpen = () => {
    onOpen();
    openChat();
  };

  const onChatClose = () => {
    onClose();
    closeChat();
  };

  return (
    <Box>
      <Button
        p="2px 6px 2px 2px"
        borderRadius="2"
        marginLeft="12px"
        cursor="pointer"
        _hover={{
          background: "darkRgba.50",
        }}
        background="#FFFFFF"
      >
        <Flex alignItems="center">
          <Tooltip
            hasArrow
            label={"チャットを表示"}
            placement="top"
            borderRadius="4px"
            backgroundColor="rgba(97, 97, 97, 0.9)"
          >
            <Flex
              onClick={() => {
                if (isOpen) {
                  onChatClose();
                } else {
                  onChatOpen();
                }
              }}
            >
              <Box p="1px" w="30px" h="30px" position="relative">
                {/* 赤ぽちは復活の可能性があるので、残しておきます
                {unreadAllChatMessages > 0 && (
                  <Box
                    textAlign="center"
                    background="red"
                    borderRadius="80%"
                    w="8px"
                    h="8px"
                    position="absolute"
                    right="2px"
                    top="2px"
                  />
                )} */}
                <Icon
                  as={BsFillChatLeftTextFill}
                  aria-label="チャットの表示"
                  borderRadius="5px"
                  cursor="pointer"
                  w="20px"
                  h="20px"
                  mt="5px"
                  color={
                    unreadAllChatMessages > 0
                      ? "rgba(0, 160, 233, 1)"
                      : "#BBC7D4"
                  }
                />
              </Box>
              {isNewMessageText && (
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  新しいメッセージがあります
                </Text>
              )}
            </Flex>
          </Tooltip>
        </Flex>
      </Button>
      <Box
        zIndex={5}
        w="100%"
        h="100dvh"
        position="absolute"
        backgroundColor="rgba(0,0,0,0.1)"
        top="0"
        left="0"
        display={isOpen ? "block" : "none"}
        onMouseDown={() => {
          onChatClose();
        }}
      ></Box>
      <Box
        display={isOpen ? "block" : "none"}
        w={`${frameWidth}px`}
        height={`${frameHeight}px`}
        m="0px"
        shadow="md"
        p="0px"
        zIndex={6}
        position="absolute"
        top={`${HEADER_HEIGHT + 5}px`}
        borderRadius="4px"
        right="10px"
        overflow="hidden"
      >
        <iframe
          // className="hide-scrollbar"
          allow="fullscreen"
          ref={frameRef}
          title="rura-chat"
          width={`${frameWidth}px`}
          height={`${frameHeight}px`}
          scrolling="no"
          src={""}
          style={{
            maxWidth: "592px",
            zIndex: 1000,
          }}
        />
      </Box>
    </Box>
  );
});
