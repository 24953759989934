export const addZero = (num: number) => {
  if (num.toString().length === 1) {
    return `0${num.toString()}`;
  }
  return num.toString();
};

/**
 * 文字列が空文字であるかを判定します。
 *
 * 以下のいずれかの条件を満たす場合は false、それ以外は true を返します。
 *
 * - v が null または undefined
 * - v が空文字
 */
export const isBlank = (v: string | undefined | null): v is string => {
  return v == null || v === "";
};
