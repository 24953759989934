import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { usePostReceveMessage } from "src/hooks/message/usePostReceveMessage";

export type UseIsVideoUnavailable = {
  /** ツールチップを表示させるか */
  isOpenTooltip: boolean;

  /** ビデオが使用不可であるか */
  isVideoUnavailable: boolean;
};

/**
 * カメラ使用不可 Hooks
 */
export const useIsVideoUnavailable = (): UseIsVideoUnavailable => {
  const [isVideoUnavailable, setIsVideoUnavailable] = useState(false);

  /** カメラを使用不可にします */
  const makeVideoUnavailable = useCallback(() => {
    setIsVideoUnavailable(true);
  }, []);

  /** イベント受信時 */
  usePostReceveMessage("video_unavailable", makeVideoUnavailable);

  // ツールチップ表示制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // カメラ使用不可の場合、10秒はツールチップを表示させる。
  useEffect(() => {
    if (!isVideoUnavailable) return;

    onOpen();
    const timer = setTimeout(onClose, 10 * 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [isVideoUnavailable]);

  return {
    isOpenTooltip: isOpen,
    isVideoUnavailable,
  };
};
