import { Box, Image, Text, Flex, Spacer } from "@chakra-ui/react";
import { FiHelpCircle } from "react-icons/fi";
import logo from "src/images/rura_logo_blue.svg";
import { ChatFrame } from "src/components/ChatFrame";

type Props = {
  isFullScreenVideo: boolean;
  isChat: boolean;
};

export const Header = ({ isFullScreenVideo, isChat }: Props) => {
  const viewChat = isChat && process.env.REACT_APP_CHAT_URL ? true : false;

  return (
    <Box
      display={isFullScreenVideo ? "none" : "block"}
      style={{
        position: "fixed",
        width: "100%",
        height: "48px",
        left: "0",
        top: "0",
        backgroundColor: "#fff",
        zIndex: "3",
      }}
    >
      <Flex
        alignItems={"center"}
        style={{
          position: "absolute",
          top: "50%",
          transform: "translate(0,-50%)",
          width: "100%",
        }}
      >
        <Flex ml="16px">
          <Image src={logo} width="auto" height="20px" />
          <Text fontSize="12px" ml="4px" display={"block"}>
            スタッフ間接続
          </Text>
        </Flex>
        <Spacer />
        {viewChat && <ChatFrame />}
        <Box
          p="3px"
          width="20px"
          height="20px"
          borderRadius="4px"
          cursor="pointer"
          mt="18px"
          style={{
            transform: "translate(0,-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          _hover={{
            background: "rgba(0, 0, 0, 0.13333)",
          }}
          onClick={() => {
            window.open(
              "https://support.timeleap-rura.com/",
              "_blank",
              "noreferrer"
            );
          }}
        >
          <FiHelpCircle
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};
