import { useEffect } from "react";
import { useParams } from "src/pages/Main/hooks/useParams";
import { useEvent } from "react-use";

export const useMain = (frameRef: React.RefObject<HTMLIFrameElement>) => {
  const { url, pcId, pcName } = useParams();
  const viewChat = localStorage.getItem("view_chat") === "TRUE" ? true : false;
  console.warn("url", url);
  const transLogin = () => {
    window.location.href = "/login";
  };

  useEffect(() => {
    if (url && pcId) {
      if (frameRef && frameRef.current) {
        console.log("url", url);
        frameRef.current.src = url;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [url, pcId]);

  useEvent("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      transLogin();
    }
  });

  return { transLogin, pcId, pcName, viewChat };
};
