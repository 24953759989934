import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { usePostReceveMessage } from "src/hooks/message/usePostReceveMessage";

export type UseCameraChangeFailed = {
  /** ツールチップを表示させるか */
  isOpenTooltip: boolean;

  /** カメラ切り替えに失敗しているか */
  isCameraChangeFailed: boolean;
};

/**
 * カメラ切り替え失敗 Hooks
 */
export const useCameraChangeFailed = (): UseCameraChangeFailed => {
  const [isFailed, setIsFailed] = useState(false);
  const makeFailed = useCallback(() => setIsFailed(true), []);

  /** イベント受信時 */
  usePostReceveMessage("failed_to_change_camera", makeFailed);

  // ツールチップ表示制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // カメラ使用不可の場合、10秒はツールチップを表示させる。
  useEffect(() => {
    if (!isFailed) return;

    onOpen();
    const timer = setTimeout(onClose, 10 * 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [isFailed]);

  return {
    isCameraChangeFailed: isFailed,
    isOpenTooltip: isOpen,
  };
};
