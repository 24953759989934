import { Box, Flex } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { FiRepeat } from "react-icons/fi";
import {
  IoClose,
  IoMicOffOutline,
  IoMicOutline,
  IoVideocamOffOutline,
  IoVideocamOutline,
} from "react-icons/io5";
import { CircleButton } from "src/components/Button/CircleButton";
import { Tooltip } from "src/components/tooltip/Tooltip";
import { DeviceSettingButton } from "src/pages/Main/components/modal/DeviceSetting/DeviceSettingButton";
import { useDevice } from "src/pages/Main/hooks/useDevice";
import { useMessageEvent } from "src/pages/Main/hooks/useMessageEvent";
import DateTime from "src/util/DateTime";
import { useIsVideoUnavailable } from "../hooks/device/useIsVideoUnavailable";
import { useCameraChangeFailed } from "../hooks/device/useCameraChangeFailed";
import { useMicUnavailable } from "../hooks/device/useMicUnavailable";

type Props = {
  transLogin: () => void;
  setIsFullScreenVideo: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreenVideo: boolean;
};

export const Footer = ({
  transLogin,
  setIsFullScreenVideo,
  isFullScreenVideo,
}: Props) => {
  const {
    micMute,
    setMicMute,
    videoMute,
    setVideoMute,
    setReception,
    devices,
    setDevices,
    micDeviceId,
    setMicDeviceId,
    cameraDeviceId,
    setCameraDeviceId,
    usingDevice,
    setUsingDevice,
    cameraToggle,
    setCameraToggle,
  } = useDevice();

  useMicUnavailable();
  const { isOpenTooltip, isVideoUnavailable } = useIsVideoUnavailable();
  const { isOpenTooltip: isOpenTooltipByCameraChange, isCameraChangeFailed } =
    useCameraChangeFailed();

  const { sendAddMessage } = useMessageEvent({
    setMicMute,
    setVideoMute,
    setReception,
    setDevices,
    micDeviceId,
    cameraDeviceId,
    setUsingDevice,
    cameraToggle,
    setIsFullScreenVideo,
  });

  const sendMessage = (
    messageType: "videoMute" | "micMute" | "changeCamera"
  ) => {
    if (messageType === "videoMute") {
      sendAddMessage({
        action: "video_mute",
        from: "rura",
        time: new DateTime().now(),
        data: {
          mute: !videoMute,
        },
      });
    } else if (messageType === "micMute") {
      sendAddMessage({
        action: "mic_mute",
        from: "rura",
        time: new DateTime().now(),
        data: {
          mute: !micMute,
        },
      });
    } else if (messageType === "changeCamera") {
      console.warn("カメラ切り替え");
    }
  };

  return (
    <Box
      style={{
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        height: "96px",
        display: isFullScreenVideo ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "4",
      }}
    >
      <Flex justifyContent={"center"}>
        <CircleButton
          icon={micMute ? IoMicOffOutline : IoMicOutline}
          iconSize="small"
          text="マイク"
          color={micMute ? "red" : "blue"}
          style={{ marginLeft: "0px" }}
          isReception={true}
          onClick={() => sendMessage("micMute")}
        />
        <Tooltip
          isOpen={isOpenTooltip}
          hasArrow
          placement="top"
          label="カメラ接続に問題があるので使用できません。"
        >
          <CircleButton
            icon={videoMute ? IoVideocamOffOutline : IoVideocamOutline}
            iconSize="small"
            text="カメラ"
            color={videoMute ? "red" : "blue"}
            style={{ marginLeft: "16px" }}
            isReception={true}
            disabled={isVideoUnavailable}
            onClick={() => sendMessage("videoMute")}
          />
        </Tooltip>
      </Flex>
      <Box
        style={{
          position: "absolute",
          left: "16px",
          top: "50%",
          transform: "translate(0,-50%)",
        }}
      >
        {isMobile ? (
          <Tooltip
            isOpen={isOpenTooltipByCameraChange}
            hasArrow
            placement="top"
            label="現在カメラ切替は使用できません。"
          >
            <CircleButton
              icon={FiRepeat}
              iconSize="small"
              text="カメラ切替"
              color="blue"
              style={{ zIndex: 10 }}
              isReception={true}
              disabled={isCameraChangeFailed || isVideoUnavailable}
              onClick={() =>
                setCameraToggle((prev) =>
                  prev === "user" ? "environment" : "user"
                )
              }
            />
          </Tooltip>
        ) : (
          <DeviceSettingButton
            isReception={true}
            devices={devices}
            micDeviceId={micDeviceId}
            setMicDeviceId={setMicDeviceId}
            cameraDeviceId={cameraDeviceId}
            setCameraDeviceId={setCameraDeviceId}
            usingDevice={usingDevice}
          />
        )}
      </Box>
      <Box
        style={{
          position: "absolute",
          right: "16px",
          top: "50%",
          transform: "translate(0,-50%)",
        }}
      >
        <CircleButton
          icon={IoClose}
          iconSize="meddium"
          text="接続終了"
          onClick={transLogin}
          color="red"
          style={{}}
        />
      </Box>
    </Box>
  );
};
