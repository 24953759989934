import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/react";

/**
 * ツールチップ
 *
 * ChakraUI の Tooltip を薄くラップしたコンポーネントです。
 * 以下のプロパティはデフォルト値が設定されています。
 *
 * - rounded: "md"
 * - w: "148px"
 * - fontSize: "12px"
 * - py: "4px"
 *
 * 必要に応じて上書きしてください。
 */
export const Tooltip = ({
  rounded = "md",
  w = "148px",
  fontSize = "12px",
  py = "4px",
  ...props
}: TooltipProps) => (
  <ChakraTooltip
    rounded={rounded}
    w={w}
    fontSize={fontSize}
    py={py}
    {...props}
  />
);
