import { useEffect, useState } from "react";
import { useEvent } from "react-use";
import { ApproveFrameReceiveAction } from "src/const";
import { RuraInitEvent } from "src/hooks/message/event/receive";
import { Devices, UseDevice } from "src/interface/Devices";
import { actionMessageType } from "src/interface/Messages";
import DateTime from "src/util/DateTime";

export const useMessageEvent = ({
  setMicMute,
  setVideoMute,
  setReception,
  setDevices,
  micDeviceId,
  cameraDeviceId,
  cameraToggle,
  setUsingDevice,
  setIsFullScreenVideo,
}: {
  setMicMute: (micMute: boolean) => void;
  setVideoMute: (videoMute: boolean) => void;
  setReception: (isReception: boolean) => void;
  setDevices: (devices: Devices) => void;
  micDeviceId: string | undefined;
  cameraDeviceId: string | undefined;
  cameraToggle: "user" | "environment";
  setUsingDevice: (usingDevice: UseDevice | undefined) => void;
  setIsFullScreenVideo: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [frameWindow, setFrameWindow] = useState<Window | null>(null);

  useEffect(() => {
    console.warn("change mic", micDeviceId);
    sendAddMessage({
      action: "change_mic",
      from: "rura",
      time: new DateTime().now(),
      data: {
        deviceId: micDeviceId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [micDeviceId]);

  useEffect(() => {
    console.warn("change camera", cameraDeviceId);
    sendAddMessage({
      action: "change_camera",
      from: "rura",
      time: new DateTime().now(),
      data: {
        deviceId: cameraDeviceId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [cameraDeviceId]);

  useEffect(() => {
    console.warn("change camera", cameraToggle);
    sendAddMessage({
      action: "change_camera_smart_phone",
      from: "rura",
      time: new DateTime().now(),
      data: {
        deviceId: cameraToggle,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is intentional
  }, [cameraToggle]);

  const handleRuraInit = (message: RuraInitEvent) => {
    console.warn("初期データ", message.data);
    setDevices(message.data);
  };

  // HACK: usePostReceveMessage に移行させる
  useEvent(
    "message",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- refactor it later.
    (event: any) => {
      if (event.data) {
        try {
          const message = JSON.parse(event.data);
          if (
            message.action &&
            ApproveFrameReceiveAction.includes(message.action)
          ) {
            setFrameWindow(event.source);
            console.warn("rura-connectでメッセージを受信しました", message);
            if (message.action === "mic_mute_result") {
              setMicMute(message.data.mute);
            }
            if (message.action === "video_mute_result") {
              setVideoMute(message.data.mute);
            }
            if (message.action === "reception_start") {
              setReception(true);
            }
            if (message.action === "reception_end") {
              setReception(false);
              setMicMute(message.data.micMute);
              setVideoMute(message.data.videoMute);
            }
            if (message.action === "rura_init") {
              handleRuraInit(message);
            }
            if (message.action === "during_rest") {
              console.warn("休憩状態");
            }
            if (message.action === "during_wait") {
              console.warn("待機中状態");
            }
            if (message.action === "media_device_result") {
              // selectの値を変更して、表示を変える
              setUsingDevice(message.data);
            }
            if (message.action === "request_full_screen") {
              setIsFullScreenVideo(message.data.isFullScreenVideo);
            }
          }
        } catch (e) {
          // nothing to do
        }
      }
    },
    window
  );
  // const message: actionMessageType = {
  //   action: "",
  //   from: "rura",
  //   time: nowTime(),
  //   data: {
  //     memberId,
  //   },
  // };

  const sendAddMessage = (message: actionMessageType) => {
    if (frameWindow) {
      frameWindow.postMessage(JSON.stringify(message), "*");
    }
  };

  return { sendAddMessage };
};
