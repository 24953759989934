import { Select, Stack } from "@chakra-ui/react";
import { FC } from "react";
import { Device } from "src/interface/Devices";
type Props = {
  data?: Device[];
  onChange: (value: string) => void;
  defaultValue?: string;
};
export const SelectBox: FC<Props> = (props: Props) => {
  const onChange = props.onChange;
  const defaultValue = props.defaultValue;

  return (
    <>
      <Stack>
        <Select
          borderColor="rgba(0, 0, 0, 0.15)"
          fontSize="14px"
          w="100%"
          h="40px"
          mt="14px"
          borderRadius="4px"
          lineHeight="16px"
          cursor="pointer"
          _hover={{
            background: "darkRgba.50",
          }}
          _active={{
            background: "darkRgba.100",
          }}
          _focus={{
            outline: "none",
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {props.data?.map(({ label, deviceId }, index) => {
            return (
              <option
                value={deviceId}
                key={index}
                selected={deviceId === defaultValue}
              >
                {label}
              </option>
            );
          })}
        </Select>
      </Stack>
    </>
  );
};
