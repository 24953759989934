import { Feature, FeatureToggle } from "src/gql/generated/graphql";

export const useFeatureToggle = () => {
  // const companyFeatureToggles = me?.company.featureToggles;

  const isFeatureToggle = (
    companyFeatureToggles: Array<FeatureToggle>,
    feature: Feature,
    pcFeatureToggles?: Array<FeatureToggle>
  ) => {
    const companyFeatureToggle = companyFeatureToggles.find(
      (companyToggle) => companyToggle.feature === feature
    );
    if (pcFeatureToggles) {
      const pcFeatureToggle = pcFeatureToggles.find(
        (pcToggle) => pcToggle.feature === feature
      );
      if (!companyFeatureToggle?.enabled) return false;
      return pcFeatureToggle?.enabled;
    }
    return companyFeatureToggle?.enabled;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- refactor it later.
  const isEnable = (companyFeatureToggles: any, feature: Feature) => {
    if (!companyFeatureToggles) return false;
    return isFeatureToggle(companyFeatureToggles, feature);
  };

  return { isEnable };
};
