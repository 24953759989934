import { useState } from "react";
import { Devices, UseDevice } from "src/interface/Devices";

export const useDevice = () => {
  const [micMute, setMicMute] = useState<boolean>(false);
  const [videoMute, setVideoMute] = useState<boolean>(false);
  const [isReception, setReception] = useState<boolean>(false);
  const [devices, setDevices] = useState<Devices>({
    videoDevices: [],
    micDevices: [],
  });
  const [micDeviceId, setMicDeviceId] = useState<string | undefined>(undefined);
  const [cameraDeviceId, setCameraDeviceId] = useState<string | undefined>(
    undefined
  );
  const [cameraToggle, setCameraToggle] = useState<"user" | "environment">(
    "user"
  );
  const [usingDevice, setUsingDevice] = useState<UseDevice | undefined>(
    undefined
  );

  return {
    micMute,
    setMicMute,
    videoMute,
    setVideoMute,
    isReception,
    setReception,
    devices,
    setDevices,
    micDeviceId,
    setMicDeviceId,
    cameraDeviceId,
    setCameraDeviceId,
    usingDevice,
    setUsingDevice,
    cameraToggle,
    setCameraToggle,
  };
};
