import { useCallback, useState } from "react";
import { useEvent } from "react-use";
import DateTime from "src/util/DateTime";
import {
  ApproveFrameReceiveAction,
  FRAME_RECEIVE_ACTION,
  FRAME_SEND_ACTION,
} from "../const";
import { UserStatusType } from "../interface/Chat";
import { actionMessageType } from "../interface/Messages";
import { HEADER_HEIGHT } from "../const";
import Sound from "src/audios/chat.mp3";

export const useMessageEvent = ({
  onMenuClose,
}: {
  onMenuClose: () => void;
}) => {
  const [frameWindow, setFrameWindow] = useState<Window | null>(null);
  const frameWidth =
    window.innerWidth - 10 > 592 ? 592 : window.innerWidth - 10;
  const getChatHeight = (height: number) => {
    return height - (HEADER_HEIGHT + 35) > 714
      ? 714
      : height - (HEADER_HEIGHT + 35);
  };
  const [frameHeight, setFrameHeight] = useState<number>(
    getChatHeight(window.innerHeight)
  );
  const [unreadAllChatMessages, setUnreadAllChatMessages] = useState<number>(0);
  const [isNewMessageText, setIsNewMessageText] = useState<boolean>(false);
  const getNowTime = () => {
    return new DateTime().now();
  };

  const updateUnreadAllChatMessages = useCallback(
    (num: number, isNoticeMute: boolean) => {
      setUnreadAllChatMessages(num);
      if (num > 0) {
        setIsNewMessageText(true);
        if (!isNoticeMute) {
          const audio = new Audio(Sound);
          audio.volume = 1;
          audio.play();
        }
        setTimeout(() => {
          setIsNewMessageText(false);
        }, 5000);
      }
    },
    [setUnreadAllChatMessages, setIsNewMessageText]
  );

  useEvent(
    "message",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- refactor it later.
    (event: any) => {
      if (event.data) {
        try {
          const message = JSON.parse(event.data);
          if (
            message.action &&
            ApproveFrameReceiveAction.includes(message.action)
          ) {
            setFrameWindow(event.source);
            if (message.action === FRAME_RECEIVE_ACTION.UNREAD_MESSAGES_NUM) {
              updateUnreadAllChatMessages(
                message.data.num,
                message.data.isNoticeMute ?? true
              );
            } else if (message.action === FRAME_RECEIVE_ACTION.CHAT_CLOSE) {
              closeChat();
              onMenuClose();
            }
          }
        } catch (e) {
          // nothing to do
        }
      }
    },
    window
  );

  const updateStatus = (status: UserStatusType) => {
    if (frameWindow) {
      const message: actionMessageType = {
        action: FRAME_SEND_ACTION.CHANGE_STAUTS,
        from: "insightify_web",
        time: getNowTime(),
        data: { status },
      };
      frameWindow.postMessage(JSON.stringify(message), "*");
    }
  };

  const openChat = () => {
    if (frameWindow) {
      const tmpFrameHeight = getChatHeight(window.innerHeight);
      const message: actionMessageType = {
        action: FRAME_SEND_ACTION.CHAT_OPEN,
        from: "rura",
        time: getNowTime(),
        data: {
          width: frameWidth,
          height: tmpFrameHeight,
        },
      };
      frameWindow.postMessage(JSON.stringify(message), "*");
      setFrameHeight(tmpFrameHeight);
    }
  };

  const closeChat = () => {
    if (frameWindow) {
      const message: actionMessageType = {
        action: FRAME_SEND_ACTION.CHAT_CLOSE,
        from: "rura",
        time: getNowTime(),
        data: {},
      };
      frameWindow.postMessage(JSON.stringify(message), "*");
    }
  };

  return {
    frameWidth,
    frameHeight,
    updateStatus,
    unreadAllChatMessages,
    isNewMessageText,
    openChat,
    closeChat,
  };
};
