/**
 * 受信イベント
 */
export const ReceiveActionEvent = {
  /** 初期化 */
  RURA_INIT: "rura_init" as const,

  /** 接客開始 */
  RECEPTION_START: "reception_start" as const,

  /** 接客終了 */
  RECEPTION_END: "reception_end" as const,

  /** ビデオミュート結果 */
  VIDEO_MUTE_RESULT: "video_mute_result" as const,

  /** マイクミュート結果 */
  MIC_MUTE_RESULT: "mic_mute_result" as const,

  /** 使用中デバイス通知 */
  MEDIA_DEVICE_RESULT: "media_device_result" as const,

  /** ビデオ利用不可 */
  VIDEO_UNAVAILABLE: "video_unavailable" as const,
};
