import { DeviceSettingModal } from "src/pages/Main/components/modal/DeviceSetting/DeviceSettingModal";
import { useDisclosure } from "@chakra-ui/react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { Devices, UseDevice } from "src/interface/Devices";
import { CircleButton } from "src/components/Button/CircleButton";

export const DeviceSettingButton = ({
  isReception,
  devices,
  micDeviceId,
  setMicDeviceId,
  cameraDeviceId,
  setCameraDeviceId,
  usingDevice,
}: {
  isReception: boolean;
  devices: Devices;
  micDeviceId: string | undefined;
  setMicDeviceId: (micDeviceId: string | undefined) => void;
  cameraDeviceId: string | undefined;
  setCameraDeviceId: (micDeviceId: string | undefined) => void;
  usingDevice: UseDevice | undefined;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <CircleButton
        icon={IoEllipsisHorizontalSharp}
        iconSize="small"
        text="設定"
        color="blue"
        style={{ marginLeft: "0px" }}
        isReception={isReception}
        onClick={() => {
          if (isReception) {
            onOpen();
          }
        }}
      />
      <DeviceSettingModal
        isOpen={isOpen}
        onClose={onClose}
        devices={devices}
        micDeviceId={micDeviceId}
        setMicDeviceId={setMicDeviceId}
        cameraDeviceId={cameraDeviceId}
        setCameraDeviceId={setCameraDeviceId}
        usingDevice={usingDevice}
      />
    </>
  );
};
